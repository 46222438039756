import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import { LangContext } from "../hooks/lang.js"

const ProjetsComp = ({ dynamicData }) => {
  let langContext = useContext(LangContext)
  let french = langContext && langContext.lang && langContext.lang === "fr-CA"

  let [ hovering, setHovering ] = useState(false)
  let [ phareUrl, setPhareUrl ] = useState(null)
  let [ pharePosition, setPharePosition ] = useState(null)
  const showPhare = e => {
    e.persist()
    e.preventDefault()
    if (e.target.dataset.phareurl && typeof window !== undefined) {
      setHovering(true)
      setPhareUrl(e.target.dataset.phareurl)

      let newPos = {
        left: `${window.innerWidth * Math.random()}`,
        top: `${window.innerHeight * Math.random()}`,
      }

      if (newPos.left < (window.innerWidth / 100) * 5) {
        newPos.left = (window.innerWidth / 100) * 5
      }
      if (newPos.left > (window.innerWidth / 100) * 65) {
        newPos.left = (window.innerWidth / 100) * 65
      }

      if (newPos.top < (window.innerHeight / 100) * 10) {
        newPos.top = (window.innerHeight / 100) * 10
      }
      if (newPos.top > (window.innerHeight / 100) * 40) {
        newPos.top = (window.innerHeight / 100) * 35
      }
      newPos.left = parseInt(newPos.left) + "px"
      newPos.top = parseInt(newPos.top) + "px"

      setPharePosition(newPos)
    } else {
      setPhareUrl(null)
    }
  }
  const hidePhare = e => {
    setHovering(false)
    setPhareUrl(null)
  }

  if (dynamicData) {
    return (
      <section className="collaborateurs">
        <h2 className="collaborateurs__header">
          {french
            ? "Notre agence fait équipe avec des Collaborateurs.rices qui forment l’essence même de nos créations. "
            : "Our agency teams up with creators that are the very roots of our creations"}
        </h2>
        <ul className="collaborateurs__list">
          {dynamicData.map(c => (
            <li key={c.images[ 0 ] || Math.random()}>
              <Link
                data-phareurl={c.imagePhare}
                onMouseEnter={showPhare}
                onMouseLeave={hidePhare}
                className="collaborateur__link"
                to={`/projet/${c.id}`}
                state={{ projet: c }}
              >
                {c.titre}
              </Link>
            </li>
          ))}
        </ul>
        {hovering && (
          <img
            name={phareUrl}
            className="phare"
            src={phareUrl}
            alt="sample from collaborator's work"
            style={pharePosition}
          />
        )}
      </section>
    )
  } else {
    return <div>{french ? "Chargement" : "Loading"}</div>
  }
}

export default ProjetsComp
