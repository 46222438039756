import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Page from "./page"
import { Link } from "gatsby"

const ProjetComp = ({ dynamicData, id }) => {
  if (dynamicData) {
    let {
      titre,
      description,
      images,
      videos,
      disciplines,
      collaborateurs,
      liens
    } = dynamicData.find(c => c.id === id)
    return (
      <section className="collaborateur">
        <h1>{titre && titre}</h1>
        {disciplines && (
          <ul className="collaborateur__disciplines">
            {disciplines.map(d => (
              <li key={d} className="collaborateur__discipline">
                {d}
              </li>
            ))}
          </ul>
        )}
        {collaborateurs && (
          <ul className="collaborateur__disciplines">
            {collaborateurs.map(c => (
              <li key={c.id} className="collaborateur__discipline">
                <Link
                  className="collaborateur__collabo-link"
                  to={`/collaborateur/${c.id}`}
                >
                  {c.name}
                </Link>
              </li>
            ))}
          </ul>
        )}
        {description && (
          <div className="collaborateur__description">
            {documentToReactComponents(description)}
          </div>
        )}

        {liens && (
          <section className="contact__section2">
            <div className="contact__social-links">
              {liens.map(l => (
                <a
                  key={l.url}
                  className="collaborateur__social-link"
                  href={l.url}
                >
                  {l.nom}
                </a>
              ))}
            </div>
          </section>
        )}

        {images && (
          <div>
            {images.map(p => (
              <img key={p} src={p} alt={`${titre}'s portfolio item`} />
            ))}
          </div>
        )}
        {videos && (
          <div>
            {videos.map((v, i) => (
              <div className="videoContainer" key={i}>
                {v.vimeoUrl && (
                  <iframe
                    title={`vimeo iframe ${v.vimeoUrl}`}
                    src={v.vimeoUrl}
                    width={window ? window.innerWidth * 0.8 : 1}
                    height={window ? (window.innerWidth / 16) * 9 * 0.8 : 1}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullcreen
                  ></iframe>
                )}
                {v.youtubeUrl && (
                  <iframe
                    title={`youtube iframe ${v.youtubeUrl}`}
                    width={window ? window.innerWidth * 0.8 : 1}
                    height={window ? (window.innerWidth / 16) * 9 * 0.8 : 1}
                    src={v.youtubeUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
                <script
                  crossOrigin
                  src="https://player.vimeo.com/api/player.js"
                ></script>
              </div>
            ))}
          </div>
        )}
      </section>
    )
  }

  return <div>Chargement</div>
}

const ProjetWithPage = props => (
  <Page>
    <ProjetComp {...props} />
  </Page>
)
export default ProjetWithPage
