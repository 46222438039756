import React, { useContext, useEffect, useState } from "react"
import { Router } from "@reach/router"
import Page from "../components/page"
import { LangContext } from "../hooks/lang.js"
import ProjetsComp from "../components/ProjetsComp.js"
import ProjetComp from "../components/ProjetComp.js"

const ProjetsPage = ({ data }) => {

  let langContext = useContext(LangContext) || null;
  let lang = langContext ? langContext.lang : null
  let [ dynamicData, setDynamicData ] = useState(null)
  useEffect(() => {
    window.contentfulClient
      .getEntries({ content_type: "projet", locale: lang })
      .then(p => {
        let data = p.items.map(pp => ({
          id: pp.sys.id,
          order: pp.fields.order,
          titre: pp.fields.titre || null,
          sousTitre: pp.fields.sousTitre || null,
          description: pp.fields.description || null,
          collaborateurs: pp.fields.collaborateurs
            ? pp.fields.collaborateurs.map(c => ({
              name: c.fields?.name || "",
              id: c.sys.id,
            }))
            : null,
          disciplines: pp.fields.disciplines
            ? pp.fields.disciplines.map(d => d.fields.titre)
            : null,
          liens: pp.fields.liens
            ? pp.fields.liens.map(l => ({
              nom: l.fields.nom,
              url: l.fields.url,
            }))
            : null,
          imagePhare: (pp.fields.imagePhare && pp.fields.imagePhare.fields.file.url) || null,
          images:
            (pp.fields.images &&
              pp.fields.images.map(ppp => ppp.fields.file.url)) ||
            null,
          videos:
            (pp.fields.videos &&
              pp.fields.videos.map(d => ({
                youtubeUrl: d.fields.youtubeUrl || null,
                vimeoUrl: d.fields.vimeoUrl || null,
              }))) ||
            null,
        }))

        data.forEach(d =>
          d.images.forEach(di => {
            const img = new Image()
            img.src = di.url
          })
        )
        data = data.sort(function (a, b) {
          var nameA = a.titre.toLowerCase(),
            nameB = b.titre.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
        setDynamicData(data)
      })
  }, [ lang ])

  return (
    <Router>
      <ProjetsComp dynamicData={dynamicData} path="/projets/" />
      <ProjetComp dynamicData={dynamicData} path="/projet/:id" />
    </Router>
  )
}

const ProjetsWithPage = props => (
  <Page>
    <ProjetsPage {...props} />
  </Page>
)

export default ProjetsWithPage
